import request from '@/utils/axios'

// 资金流水 查询
export function turnoverAmountPage (data) {
  return request({
    url:'/supplyportal/turnoverAmount/page',
    method:'get',
    params:data
  })
}
