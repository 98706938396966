<template>
  <div class="capitalFlow bg-white p-10">
    <p class="common-tip-title mb-20 mt-10">资金流水</p>

    <ul class="tab-box" style="margin-bottom: 0;">
      <li v-for="(item, index) in tabsVal" :key="index" :class="indexs === index ? 'tab-active' : 'tab-item'" @click="clickChanges(item, index)">{{ item.title }}</li>
    </ul>
    <div style="border: 1px solid #ddd;">
      <div style="padding: 16px 20px;">
        <el-row :gutter="20">
          <el-col :span="5.5">
            <div class="grid-content bg-purple">
              <el-date-picker v-model="value2"  :clearable="false" :picker-options="pickerOptions"
                              align="left" end-placeholder="结束日期"  range-separator="至" size="small" start-placeholder="开始日期" type="daterange" unlink-panels>
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple">
              <el-select v-model="waterValue" placeholder="请选择流水类型" size="small">
                <el-option
                  v-for="item in options1"
                  :key="item.displayFlowType"
                  :label="item.label"
                  :value="item.displayFlowType">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple">
              <el-select v-model="payValue" placeholder="请选择支付方式" size="small">
                <el-option
                  v-for="item in options2"
                  :key="item.payWay"
                  :label="item.label"
                  :value="item.payWay">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple">
              <el-input v-model="inputValue" placeholder="请输入业务单号" size="small"></el-input>
            </div>
          </el-col>
          <el-col :span="2.5">
            <div class="grid-content bg-purple">
              <el-button size="small" type="primary" @click="onSubmit">查询</el-button>
            </div>
          </el-col>
          <el-col :span="2.5">
            <div class="grid-content bg-purple">
              <el-button size="small" @click="onReset">重置</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="container table-content">
        <div class="left"></div>
        <div class="right">
          <div class="right-wrapper">
            <!-- <div v-show="type !== ''" class="price-box">
              <span class="price-des">{{ type == 2 ? '支出总计：' : '收入总计：' }}<span class="price-txt">￥0.00</span></span>
            </div> -->
            <el-table :data="tableData" border height="430" style="width: 100%">
              <el-table-column :index="indexMethod"  align="center" label="序号" type="index" width="80"></el-table-column>
              <el-table-column align="center" label="业务单号" prop="orderNo" width="100"></el-table-column>
              <el-table-column align="center" label="流水类型" prop="payStatus" width="90">
                <template slot-scope="scope">
                  {{ typeText[scope.row.type] }}
                </template>
              </el-table-column>
              <el-table-column  width="90" :label="payTypes === 1 ? '收入金额' : payTypes === 2 ? '支出金额' : '收支金额' "  align="center" prop="payPrice"></el-table-column>
              <el-table-column align="center" width="90" label="支付方式" prop="payType">
                <template slot-scope="scope">
                  {{ payTypeText[scope.row.payType] }}
                </template>
              </el-table-column>
              <el-table-column  align="center" label="交易内容" prop="payContent">
                <template slot-scope="scope">
                  <span>{{ scope.row.payContent }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" width="90"  label="时间" prop="createTime" ></el-table-column>
            </el-table>
            <div style="justify-content: right;display: flex;margin-top: 20px;">
              <el-pagination
                :current-page="page.currentPage"
                :page-size="page.size"
                :page-sizes="[10, 20, 30]"
                :total="page.total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pickerOptions } from '@/views/User/FinancialManagement/dateCollation'
import { turnoverAmountPage } from '@/api/capital'
export default {
  name: 'FundingBillsIndex',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 支付类型
      options2: [{
        payWay: '0',
        label: '全部'
      }, {
        payWay: 1,
        label: '微信'
      }, {
        payWay: 2,
        label: '支付宝'
      }, {
        payWay: 3,
        label: '余额'
      }],
      // 流水类型
      options1: [{
        displayFlowType: 1,
        label: '订单收入'
      },{
        displayFlowType: 2,
        label: '订单支出'
      },
      //   {
      //   displayFlowType: 3,
      //   label: '服务费'
      // },{
      //   displayFlowType: 4,
      //   label: '税费'
      // },{
      //   displayFlowType: 5,
      //   label: '差价收入（中台）'
      // }
      ],
      // 流水类型值
      waterValue: null,
      payTypes: null,
      // 支付方式值
      payValue: null,
      // 业务单号
      inputValue: null,
      // 标签页类型
      type: '',
      // 时间带快捷选项
      pickerOptions: pickerOptions,
      // 时间选择值
      value2: '',
      // 标签内容
      indexs: 0,
      tabsVal: [{
        payStatus: '0',
        title: '全部'
      },{
        payStatus: 2,
        title: '支出'
      },{
        payStatus: 1,
        title: '收入'
      }],
      page:{
        currentPage:1,
        size:10,
        total:0
      },
      date:{
        startTime:null,
        endTime:null
      },
    }
  },
  computed: {
    // 流水类型
    typeText(){
      return {
        1:'订单收入',
        2:'订单支出',
        // 3:'服务费',
        // 4:'税费',
        // 5:'差价收入（中台）'
      }
    },
    //  支付状态
    payTypeText(){
      return {
        1:'微信',
        2:'支付宝',
        3:'余额'
      }
    },
  },
  created() {
    this.getPage(this.page)
  },
  methods: {
    getPage(page){
      turnoverAmountPage({
        current: page.currentPage,
        size: page.size,
        startTime: this.value2 === null ? null : this.value2[0],
        endTime:this.value2 === null ? null : this.value2[1],
        type:this.waterValue || null, // 流水类型
        payStatus: this.payTypes === '' ? null : this.payTypes,
        orderNo:this.inputValue || null,//搜索单号，
        payType:this.payValue == '0' ? null : this.payValue // 支付类型 1:微信 2：支付宝 3:余额
      }).then(res => {
        console.log('console',res)
        this.tableData = res.data.page.records
        this.page.total = res.data.page.total
        this.page.size = res.data.page.size
        this.page.currentPage = res.data.page.current
      }).catch((err) => {

      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    indexMethod(index) {
      return index +1
    },
    // 查询
    onSubmit() {
      this.getPage(this.page)
    },
    clickChanges(e, index) {
      this.indexs = index
      this.payTypes = e.payStatus
      this.payTypes = e.payStatus === '0' ? '' : e.payStatus
      if(this.payTypes === '') {
        this.options1 = [{
          displayFlowType: 1,
          label: '订单收入'
        },{
          displayFlowType: 2,
          label: '订单支出'
        }]
        this.waterValue = null
      } else if (this.payTypes === 2) {
        this.options1 = [{
          displayFlowType: 2,
          label: '订单支出'
        }]
        this.waterValue = this.options1[0].displayFlowType
      } else if(this.payTypes === 1) {
        this.options1 = [{
          displayFlowType: 1,
          label: '订单收入'
        }]
        this.waterValue = this.options1[0].displayFlowType
      }
      this.getPage(this.page)
    },
    // 重置
    onReset() {
      this.payValue = ''
      this.waterValue = ''
      this.value2 = ''
      this.inputValue = ''
      this.getPage(this.page)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}
.left {
  width: 0px;
}
.right{
  flex: 1;
  position: relative;
}
.right-wrapper{
  width: 100%;
}
.price-box {
  margin-bottom: 25px;
  text-align: right;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #666;
  .price-des {
    padding: 0 19px;
  }
  .price-des:last-child {
    border: none;
    padding-right: 0;
  }
  .price-txt {
    font-weight: 700;
    color: #29a6ff;
  }
}
.table-content {
  padding: 20px;
}
.tab-active, .tab-item {
  width: 86px;
  height: 40px;
  font-size: 14px;
  background: #f9f9f9;
  border-radius: 4px 4px 0 0;
  color: #666;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.tab-active {
  font-weight: 700;
  color: #2ba7ff;
  background: #fff;
  border: none;
}
.capitalFlow {
  .tab-box {
    position: relative;
    top: 2px;
    display: flex;
    li {
      &::before{
        left: 0;
        bottom: 0;
        content: ' ';
        display: flex;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: white;
      }
      position: relative;
      border: 1px solid #ddd;
      border-bottom: none;
    }
  }
}
ul {
  padding: 0;
}
</style>
